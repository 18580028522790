@import '../../theme/_variables.less';

.ant-table-wrapper {
  background: white;
  padding: 24px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  border-radius: 8px;
  max-width: 1440px;
  width: 100%;
}

.ant-modal-body {
  .ant-table-wrapper {
    border-radius: 0;
    box-shadow: unset;
    padding: 0;
  }
}

.ant-table-thead > tr > th {
  border-bottom: 2px solid @light-blue-primary;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
}

.ant-table-tbody > tr > td {
  font-weight: 400;
  font-size: 20px;
  line-height: 22px;
}

tr.drop-over-downward td {
  border-bottom: 2px dashed @light-blue-primary;
}
tr.drop-over-upward td {
  border-top: 2px dashed @light-blue-primary;
}
