.ant-tabs-nav-wrap {
  margin-left: 4px;
}
.ant-tabs-content-holder {
  .ant-table-wrapper {
    border-top-left-radius: 0;
  }
}

.ant-tabs-top > .ant-tabs-nav {
  margin-bottom: 12px;
}
