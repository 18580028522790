@import '../../theme/_variables.less';

.ant-tag {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

.ant-tag.ant-tag-success {
  color: @light-green-primary;
  border-color: @light-green-primary;
  background: @light-green-background;
}

.ant-tag.ant-tag-processing {
  color: @orange-primary;
  border-color: @orange-primary;
  background: @orange-background;
}

.ant-tag.ant-tag-default {
  color: @gray-border;
  border-color: @gray-border;
  background: @gray-background;
}
