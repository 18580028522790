.ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  padding-right: 40px;
}

.ant-select .ant-select-clear {
  right: 32px;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: @gray-border;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  color: @gray-disable-text;
}
