.ant-card {
  border-radius: 10px;
  box-shadow: 0px 3px 6px #ebf5fc29;
}

.ant-card-body {
  @media @xs {
    padding: 16px;
  }
}
