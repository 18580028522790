.ant-modal-content {
  border-radius: 8px;
  overflow: hidden;

  .ant-modal-body {
    padding: 0;
    .custom-modal-header {
      padding: 0 52px 0 16px;
      background: @light-blue-primary;
      align-items: center;
      display: flex;
      height: 66px;
      color: white;
      span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: inherit;
      }
      .close-icon {
        position: absolute;
        right: 16px;
        color: inherit;
      }
    }
    .custom-modal-body {
      padding: 16px;
      max-height: calc(100vh - 300px);
      overflow-y: auto;
    }
    .custom-modal-footer {
      padding: 16px;
    }
  }
}
