.body {
  :global {
    @import '_backend/theme/backend.less';

    .ant-layout-header {
      display: flex;
      justify-content: center;
      height: 60px;
      line-height: 60px;
      background: #fff;
      padding: 0 24px;
    }

    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
      color: @gray-primary-text;
    }

    .ant-menu.ant-menu-horizontal {
      border: 0;
      width: 100%;
      min-width: 80px;
      max-width: calc(100vw - 340px);
      padding-left: 16px;
      .ant-menu-title-content {
        font-weight: 700;
        font-size: 24px;
        padding: 4px 8px;
        &:hover {
          color: @gray-primary-text;
          background-color: @light-blue-background;
        }
      }
      .ant-menu-item {
        padding: 0 12px;
      }
      .ant-menu-item-selected {
        .ant-menu-title-content {
          color: @gray-primary-text;
          background-color: @light-blue-background;
        }
      }
    }

    .ant-menu-overflow-item.ant-menu-overflow-item-rest {
      display: flex;
      align-items: center;

      .ant-menu-submenu-title {
        display: flex;
        &:hover {
          color: @gray-primary-text;
          background-color: @light-blue-background;
        }
      }
      .anticon {
        padding: 4px 12px;
      }
      svg {
        font-size: 18px;
        color: @gray-primary-text;
      }
      &.ant-menu-submenu-selected {
        .ant-menu-submenu-title {
          color: @gray-primary-text;
          background-color: @light-blue-background;
        }
      }
    }

    .ant-menu:not(.ant-menu-horizontal) {
      .ant-menu-item:hover {
        color: @gray-primary-text;
        background-color: @light-blue-background;
      }
      .ant-menu-title-content {
        font-weight: 700;
        font-size: 24px;
      }
      .ant-menu-item-selected {
        color: @gray-primary-text;
        background-color: @light-blue-background;
      }
    }
    .ant-layout-content {
      background: linear-gradient(320deg, #dff2fd 0%, #ffffff 100%);
      min-height: calc(100vh - 60px);
    }

    // submenu
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
    .ant-menu-horizontal > .ant-menu-item::after,
    .ant-menu-horizontal > .ant-menu-submenu::after,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
      border-bottom: 0;
    }
    .ant-menu-horizontal:not(.ant-menu-dark) .ant-menu-submenu-title,
    .ant-menu-light .ant-menu-submenu-title:hover,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
    .ant-menu-vertical .ant-menu-submenu-selected,
    .ant-menu-vertical-left .ant-menu-submenu-selected,
    .ant-menu-vertical-right .ant-menu-submenu-selected {
      color: @gray-primary-text;
    }
    .ant-menu-submenu.ant-menu-submenu-vertical.ant-menu-submenu-selected {
      background-color: @light-blue-background;
    }
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active .ant-menu-title-content,
    .ant-menu-overflow-item.ant-menu-submenu.ant-menu-submenu-horizontal.ant-menu-submenu-selected
      .ant-menu-title-content {
      background-color: @light-blue-background;
    }
    .ant-menu-light .ant-menu:not(.ant-menu-inline) {
      .ant-menu-submenu-arrow,
      .ant-menu-submenu-open {
        color: @gray-primary-text;
        background-color: @light-blue-background;
      }
    }
  }
}

// test changes
