@import 'theme/_variables.less';

.ant-input {
  height: 66px;
  border-radius: 6px;
  border-color: @soft-gray;
  font-size: 24px;
}
.ant-input[disabled] {
  border-color: @soft-gray;
}

.ant-input-affix-wrapper {
  border-radius: 6px;
  border-color: @soft-gray;
  padding: 0px 16px;
  .ant-input {
    padding: 0 10px;
  }
}

.ant-input.ant-input-sm {
  height: 40px;
}

/* WebKit, Edge */
.ant-input::-webkit-input-placeholder {
  color: @dark-gray;
  font-size: 24px;
  font-weight: 400;
}
/* Firefox 4-18 */
.ant-input:-moz-placeholder {
  color: @dark-gray;
  font-size: 24px;
  font-weight: 400;
}
/* Firefox 19+ */
.ant-input::-moz-placeholder {
  color: @dark-gray;
  font-size: 24px;
  font-weight: 400;
}
/* IE 10-11 */
.ant-input:-ms-input-placeholder {
  color: @dark-gray;
  font-size: 24px;
  font-weight: 400;
}
/* Edge */
.ant-input::-ms-input-placeholder {
  color: @dark-gray;
  font-size: 24px;
  font-weight: 400;
}
/* MODERN BROWSER */
.ant-input::placeholder {
  color: @dark-gray;
  font-size: 24px;
  font-weight: 400;
}

// form label
.ant-col.ant-form-item-label {
  padding: 0;
  label {
    color: @dark-gray;
    font-weight: 400;
    font-size: 18px;
  }
}

// form item
.ant-row.ant-form-item {
  margin-bottom: 10px;
}

// number input
.ant-input-number-input {
  text-align: right;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: none;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
  display: inline-block;
  margin-left: 4px;
  margin-top: 4px;
  color: @red-primary;
  font-size: 24px;
  line-height: 0;
  content: '*';
}
