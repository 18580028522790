.ant-modal-content {
  border-radius: 8px;
  overflow: hidden;

  .ant-modal-body {
    padding: 0;
    .custom-modal-header {
      padding: 0 52px 0 16px;
      background: @light-blue-primary;
      align-items: center;
      display: flex;
      height: 66px;
      color: white;
      span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: inherit;
      }
      .close-icon {
        position: absolute;
        right: 16px;
        color: inherit;
      }
    }
    .custom-modal-body {
      padding: 16px;
    }
    .custom-modal-footer {
      padding: 16px;
    }
  }
  .ant-modal-close {
    right: 16px;
    top: 16px;
    @media @xs {
      right: 8px;
      top: 8px;
    }
  }
}

.ant-modal-wrap {
  top: 16px;
  @media @xs {
    top: 70px;
  }
}

.custom-modal.custom-confirm-modal {
  .custom-modal-footer {
    button {
      height: 40px;
    }
  }
}
