@import 'theme/_variables.less';

.ant-picker {
  height: 66px;
  border-radius: 6px;
  color: @dark-gray;
  border: 1px solid @soft-gray;
  width: 100%;
}

.ant-picker-suffix > * {
  color: @dark-gray;
}

.ant-picker-panel-container {
  border-radius: 16px;

  .ant-picker-header {
    border-bottom: unset;
    height: 40px;
    .ant-picker-header-view button:hover {
      color: @date-picker-heder-hover;
    }
    background-color: @light-blue-primary;
    border-radius: 16px 16px 0 0;
    color: white;
  }
  .ant-picker-body {
    padding: 0;
    .ant-picker-content {
      width: 100%;
      display: inline-table;
      thead {
        background: @date-picker-month-bg;
        height: 40px;
      }
      .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        border: 1px solid @light-blue-primary;
        border-radius: 50px;
        content: '';
      }
      .ant-picker-cell-inner {
        border-radius: 50px;
        width: 37px;
        height: 37px;
        line-height: 37px;
      }
    }
  }

  .ant-picker-footer {
    display: none;
  }

  .ant-picker-prev-icon::after,
  .ant-picker-next-icon::after {
    border: 0 solid white;
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    width: 7px;
    height: 7px;
    border-width: 1.5px 0 0 1.5px;
    content: '';
  }

  .ant-picker-super-prev-icon::after,
  .ant-picker-super-next-icon::after {
    position: absolute;
    top: 4px;
    left: 4px;
    display: inline-block;
    width: 7px;
    height: 7px;
    border: 0 solid white;
    border-width: 1.5px 0 0 1.5px;
    content: '';
  }

  .ant-picker-super-prev-icon::before,
  .ant-picker-super-next-icon::before {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    width: 7px;
    height: 7px;
    border: 0 solid white;
    border-width: 1.5px 0 0 1.5px;
    content: '';
  }
}
.ant-picker-input > input {
  font-size: 28px;
}
