@import '../../theme/_variables.less';

.ant-pagination {
  padding-right: 16px;
  padding-top: 16px;
}

.ant-pagination-item {
  border: 1px solid @gray-border;
}
.ant-pagination-item-active {
  border: 1px solid @light-blue-primary;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  border-color: @gray-border;
}
.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link {
  border-color: #d9d9d9;
}
