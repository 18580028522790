.ant-select-dropdown.big-selector .ant-select-item-option-content {
  font-size: 28px;
  padding: 12px;
}
.ant-select.big-selector {
  .ant-select-selector {
    height: 66px;
  }

  .ant-select-selection-item,
  .ant-select-selection-placeholder {
    line-height: 66px;
    font-size: 28px;
  }
  .ant-select-clear {
    margin-top: -11px;
    font-size: 22px;
    right: 22px;
  }
  .ant-select-arrow {
    right: 18px;
  }
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: @soft-gray;
}
