body {
  :global {
    @import 'theme/_responsive.less';

    .ant-layout-footer {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 60px;
      background: #404040;
      @media @xs {
        padding: 0;
        height: 80px;
      }
    }
  }
}
