// Layout
.flex {
  display: flex;
}

// Sizing
.w-100 {
  width: 100%;
}
.w-300 {
  width: 300px;
}

// Spacing
.pt-24 {
  padding-top: 24px;
  padding-bottom: 24px;
}
.pb-24 {
  padding-top: 24px;
  padding-bottom: 24px;
}
.py-24 {
  padding-top: 24px;
  padding-bottom: 24px;
}
.mt-8 {
  margin-top: 8px;
}
.mt-16 {
  margin-top: 16px;
}

// Text Align
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}

//
.cursor-pointer {
  cursor: pointer;
}
