@import '../../theme/_variables.less';

.ant-radio-button-wrapper {
  color: @gray-placeholder;
  border: 1px solid @gray-border;
}
.ant-radio-button-wrapper:first-child {
  border-left: 1px solid @gray-border;
}
input[type='radio']:focus {
  outline: none;
}

.ant-radio-disabled + span {
  color: @gray-disable-text;
}
.ant-radio.ant-radio-disabled .ant-radio-inner {
  border-color: @gray-border;
}
.ant-radio-disabled .ant-radio-inner::after {
  background-color: @gray-border;
}
