.body {
  :global {
    @import 'theme/frontend.less';

    .ant-layout {
      min-height: calc(100vh);
    }

    .ant-layout-header {
      background: none;
      z-index: 99;
      position: absolute;
      top: 36px;
      left: 0;
      right: 0;

      .ant-menu.ant-menu-horizontal {
        display: flex;
        justify-content: flex-end;
        padding-right: 30px;
        color: black;
        height: 80px;
        width: 100%;
        max-width: calc(100vw - 500px);
        background-color: #d1dde8;
        border: 0;

        .ant-menu-title-content {
          font-size: 28px;
          line-height: 80px;
          font-weight: 400;
        }
      }

      .ant-menu-horizontal > .ant-menu-submenu > .ant-menu-submenu-title {
        display: flex;
        align-items: center;
        height: 100%;
      }

      .ant-menu-horizontal > .ant-menu-item::after,
      .ant-menu-horizontal > .ant-menu-submenu::after {
        display: none;
      }
    }

    .ant-layout-content {
      background-image: url('~/src/assets/images/bg-content.png');
      background-color: white;
      background-repeat: no-repeat;
      background-blend-mode: multiply;
      background-position: top right;
    }

    .ant-layout-footer {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 60px;
      background: #404040;
      @media @xs {
        padding: 0;
        height: 80px;
      }
    }

    .profile-menu-dropdown {
      .ant-dropdown-menu {
        min-width: 160px;
      }
      .ant-dropdown-menu-item {
        height: 40px;
        &:hover {
          color: @light-blue-primary;
        }
      }
      .ant-dropdown-menu-item-icon {
        font-size: 16px;
      }
    }

    .noti-menu-popover {
      .ant-popover-inner-content {
        padding: 10px 0;
      }
    }
  }
}
