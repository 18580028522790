@import '~antd/dist/antd.less';

@import 'theme/fonts.less';
@import 'theme/_variables.less';

// Override Antd variables
@primary-color: @light-blue-primary;
@text-color: @gray-primary-text;

@font-family: 'DB Heavent', sans-serif;
@font-size-base: 20px;
@line-height-base: 22px;
@height-base: 38px;

// button
@btn-font-weight: 500;
@btn-default-color: @primary-color;
@btn-default-border: @primary-color;

// icon
@btn-square-only-icon-size: 16px;

// divider
@divider-color: @gray-border;

// Input
@input-placeholder-color: @gray-placeholder;
@input-border-color: @gray-border;
@input-hover-border-color: @gray-primary-text;
@input-disabled-color: @gray-disable-text;

// Table
@table-header-bg: @white;
@table-header-color: @gray-primary-text;
@table-row-hover-bg: @light-blue-background;
// @table-padding-vertical: 16px;
// @table-padding-horizontal: 16px;
@table-border-color: @gray-flat-button;
@table-header-cell-split-color: @white;

@border-radius-base: 4px;
@card-radius: 8px;
@table-border-radius-base: @card-radius;

.rbc-overlay {
  max-height: 300px; /* Adjust height to fit your needs */
  overflow-y: auto; /* Enable vertical scrolling */
}

.rbc-overlay-header {
  position: sticky;
  top: -10px;
  background: white; /* Ensure it doesn’t blend with content */
  z-index: 10; /* Keep it above other elements */
  padding: 8px;
  border-bottom: 1px solid #ddd; /* Optional: Add a separator */
}
