@font-face {
  font-family: 'DB Heavent';
  src: url('~/public/fonts/DBHeavent-Thin.eot?') format('embedded-opentype'),
    url('~/public/fonts/DBHeavent-Thin.woff') format('woff'), url('~/public/fonts/DBHeavent-Thin.woff2') format('woff2');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'DB Heavent';
  src: url('~/public/fonts/DBHeavent-ThinItalic.eot?') format('embedded-opentype'),
    url('~/public/fonts/DBHeavent-ThinItalic.woff') format('woff'),
    url('~/public/fonts/DBHeavent-ThinItalic.woff2') format('woff2');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'DB Heavent';
  src: url('~/public/fonts/DBHeavent-Light.eot?') format('embedded-opentype'),
    url('~/public/fonts/DBHeavent-Light.woff') format('woff'),
    url('~/public/fonts/DBHeavent-Light.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'DB Heavent';
  src: url('~/public/fonts/DBHeavent-LightItalic.eot?') format('embedded-opentype'),
    url('~/public/fonts/DBHeavent-LightItalic.woff') format('woff'),
    url('~/public/fonts/DBHeavent-LightItalic.woff2') format('woff2');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'DB Heavent';
  src: url('~/public/fonts/DBHeavent-Regular.eot?') format('embedded-opentype'),
    url('~/public/fonts/DBHeavent-Regular.woff') format('woff'),
    url('~/public/fonts/DBHeavent-Regular.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'DB Heavent';
  src: url('~/public/fonts/DBHeavent-Italic.eot?') format('embedded-opentype'),
    url('~/public/fonts/DBHeavent-Italic.woff') format('woff'),
    url('~/public/fonts/DBHeavent-Italic.woff2') format('woff2');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'DB Heavent';
  src: url('~/public/fonts/DBHeavent-Medium.eot?') format('embedded-opentype'),
    url('~/public/fonts/DBHeavent-Medium.woff') format('woff'),
    url('~/public/fonts/DBHeavent-Medium.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'DB Heavent';
  src: url('~/public/fonts/DBHeavent-MediumItalic.eot?') format('embedded-opentype'),
    url('~/public/fonts/DBHeavent-MediumItalic.woff') format('woff'),
    url('~/public/fonts/DBHeavent-MediumItalic.woff2') format('woff2');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'DB Heavent';
  src: url('~/public/fonts/DBHeavent-Bold.eot?') format('embedded-opentype'),
    url('~/public/fonts/DBHeavent-Bold.woff') format('woff'), url('~/public/fonts/DBHeavent-Bold.woff2') format('woff2');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'DB Heavent';
  src: url('~/public/fonts/DBHeavent-BoldItalic.eot?') format('embedded-opentype'),
    url('~/public/fonts/DBHeavent-BoldItalic.woff') format('woff'),
    url('~/public/fonts/DBHeavent-BoldItalic.woff2') format('woff2');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'DB Heavent';
  src: url('~/public/fonts/DBHeavent-Black.eot?') format('embedded-opentype'),
    url('~/public/fonts/DBHeavent-Black.woff') format('woff'),
    url('~/public/fonts/DBHeavent-Black.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'DB Heavent';
  src: url('~/public/fonts/DBHeavent-BlackItalic.eot?') format('embedded-opentype'),
    url('~/public/fonts/DBHeavent-BlackItalic.woff') format('woff'),
    url('~/public/fonts/DBHeavent-BlackItalic.woff2') format('woff2');
  font-weight: 900;
  font-style: italic;
}
