@import '../../theme/_variables.less';

.ant-picker {
  height: 38px;
  color: @gray-primary-text;
  border: 1px solid @gray-border;
  width: 100%;
}
.ant-picker-suffix > * {
  color: @gray-placeholder;
}

.ant-picker.ant-picker-disabled {
  border-color: @gray-border;
}
