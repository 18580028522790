@import 'theme/_variables.less';

.ant-pagination-next,
.ant-pagination-prev {
  button {
    background-color: #f3f7f9;
    border-radius: 6px;
    border: none;
    color: @gray-border;
  }
}
.ant-pagination-item {
  border-radius: 6px;
  border: none;
  a {
    color: @light-blue-primary;
  }
}
.ant-pagination-item.ant-pagination-item-active {
  background-color: @light-blue-primary;
  a {
    color: white;
  }
}
