@import '../../theme/_variables.less';

.ant-input {
  height: 38px;
}
.ant-input.ant-input-sm {
  height: 24px;
  font-size: 16px;
  line-height: 20px;
}
.ant-input-affix-wrapper-focused,
.ant-input:focus {
  border-color: @gray-primary-text;
  box-shadow: unset;
}
.ant-input-affix-wrapper {
  height: 38px;
  .ant-input {
    height: 30px;
  }
}
.ant-input-suffix,
.ant-input-prefix {
  font-size: 14px;
}
.ant-input-affix-wrapper.ant-input-affix-wrapper-sm {
  height: 24px;
  .ant-input {
    height: 22px;
  }
  .ant-input-suffix,
  .ant-input-prefix {
    font-size: 10px;
  }
}

// autocomplete
.ant-input-clear-icon.ant-input-clear-icon-has-suffix {
  display: flex;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-show-search.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border-color: @gray-primary-text;
  box-shadow: unset;
}

// label
.ant-form-item-label > label {
  font-size: 20px;
  font-weight: 400;
  line-height: 22px;
  color: @gray-secondary-text;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: none;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
  display: inline-block;
  margin-right: 4px;
  margin-top: 4px;
  color: @red-primary;
  font-size: 24px;
  line-height: 0;
  content: '*';
}

.label-header30 .ant-form-item-label > label {
  font-size: 30px;
  font-weight: 700;
  line-height: 36px;
}
