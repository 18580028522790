.ant-btn-primary {
  background-color: @light-blue-primary;
}
.ant-btn-dangerous {
  color: @red-primary;
  border-color: @red-primary;
}
.ant-btn-dangerous.ant-btn-primary {
  border-color: @red-primary;
  background: @red-primary;
}
