@import '../../theme/_variables.less';

.ant-picker-calendar-header {
  margin-right: 12px;
  margin-bottom: 16px;
}

.ant-picker-calendar-header .ant-picker-calendar-mode-switch {
  display: none;
}

.ant-picker-content th {
  font-weight: 700;
}

.ant-picker-calendar-header .ant-picker-calendar-month-select,
.ant-picker-calendar-header .ant-picker-calendar-year-select {
  min-width: 150px;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
  border-top: 3px solid #f0f0f0;
  margin: 2px;
}

.ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end)
  .ant-picker-cell-inner {
  background-color: white;
}
